const AppConfig = {
  key: {
    LoggedIn: 'loggedInAgentLottokey',
    RefreshTokenKey: 'appRefreshTokenKey',
    TokenKey: 'appTokenKey',
    GlobalMarketKey: 'SelectedGlobalMarket'
  }
}

export default AppConfig;
